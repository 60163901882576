import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PublicService {
  claimUrl = environment.claimUrl;
  private isUserLoggedIn = new BehaviorSubject<boolean>(false);
    constructor(
        private http: HttpClient,
        private router: Router,
        private storageService: StorageService
    ) { }

    viewAllInsurers() {
        return this.http.get(environment.apiEnrolUrl + '/insurer/getAllInsurers');
    }

    getCities() {
        return this.http.get(environment.apiEnrolUrl + '/statesAndCity/getAllCities');
    }

    getNearestHospital(lat: string, lng: string) {
        let httpParm = new HttpParams().set('lat', lat).set('lon', lng);
        const httpOption = {
            params: httpParm
        };
        return this.http.get(environment.apiEnrolUrl + '/fetchHospitalWithInRange', httpOption);
    }

    providerSearchV2(data) {
        let httpParams = new HttpParams()
            .set('insurerid', data.insurerid)
            .set('providerSerach', data.displayName)
            .set('pincode', data.pincode)
            .set('city', data.city)
        const httpOptions = {
            params: httpParams
        };
        return this.http.get(environment.apiEnrolUrl + '/getNetworkSearch', httpOptions);
    }

    getCaptcha() {
        return this.http.get(environment.apiEnrolUrl + '/auth/contact-us');
      }
      saveContactUs(data: any) {
        return this.http.post(environment.apiEnrolUrl + '/contact/save', data);
      }      
      getFeedbackDetails(token){
        const params = new HttpParams().set("token",token);
        const httpOption = {
          params
        }
        return this.http.get(environment.claimUrl + '/status/get-info-feedback', httpOption);
      }
      getCashlessRequest(data){
        return this.http.post(environment.apiEnrolUrl + '/login-encrypted',data);
      }
      submitFeedback(data) {
        return this.http.post(environment.claimUrl + '/status/submit-feedback', data);
      }
    
      getPreSignedUrlByKey(bucket, key): Observable<any> {
        const param = new HttpParams().set('bucket', bucket)
                                      .set('key',key);
        const httpOptions = {
          params: param
        };
        return this.http.get<any>(environment.claimUrl  + '/status/get-pre-signed-url-by-key', httpOptions);
      }
      
      getMobileAppVersion() {
        return this.http.get(environment.apiEnrolUrl + '/getMobileAppVersion');
      }

      loginBSSO(ip: string, encryptedText: string) {
        return this.http.post(environment.apiEnrolUrl + '/auth/login-b-sso',
          {
            'userName': ip,
            'password': encryptedText,
            'isNewPortal':true
          });
      }     
      setIsUserLoggedIn(isLoggedIn: boolean) {
        this.isUserLoggedIn.next(isLoggedIn);
      }
      loginBESSO(encrypttext: string, decrypttext: string) {
        return this.http.post(environment.apiEnrolUrl + '/auth/login-be-sso',
          {
            'userName': encrypttext,
            'password': decrypttext
          });
      }
      loginVSSO(email: string, corporate: string) {


        return this.http.post(environment.apiEnrolUrl + '/auth/login-v-sso',
          {
            'userName': email,
            'password': corporate
          });
    
      }
      getClaimTrackDetails(claimNumber: any) {
        const param = new HttpParams().set('value', claimNumber);
        const httpOptions = {
          params: param
        };
        return this.http.get(environment.apiEnrolUrl + '/claim-tracking/track-claim',httpOptions);
      }

      uploadTrackingDocument(data: any) {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.post(environment.apiEnrolUrl + '/claim-tracking/upload-claim-tracking-dr-document', data, httpOptions);
      }

      completeDr(data: any) {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.post(environment.apiEnrolUrl + '/claim-tracking/complete-dr', data, httpOptions);
      }

      raiseConsumerQuery(data: any) {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.post(environment.apiEnrolUrl + '/claim-tracking/raise-consumer-query', data, httpOptions);
      }
      getInternalFeedbackDetails(token){
        const params = new HttpParams().set("token",token);
        const httpOption = {
          params
        }
        return this.http.get(this.claimUrl + '/status/internal/get-info-feedback', httpOption);
      }

    submitEmailFeedback(data) {
      return this.http.post(this.claimUrl + '/status/internal/submit-feedback-email', data);
    }
    submitSmsFeedback(data) {
      return this.http.post(this.claimUrl + '/status/internal/submit-feedback-sms', data);
    }
}