import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { EncryptService } from './encrypt.service';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private encrypt: EncryptService,
  ) {
  }

  //Setting data into storage
  async set(key: string, value: any): Promise<void> {
    // await Storage.set({
    //   key: key,
    //   value: JSON.stringify(value),
    // });
    const encToken = this.getCore('eToken');
    // setting encrypted data
    const newValue = this.encrypt.stringEncryption(value, encToken);
    await Storage.set({
      key: key,
      value: JSON.stringify(newValue),
    });
  }
  //Getting Data into Storage
  async get(key: string): Promise<any> {
    // const item = await Storage.get({ key: key });
    const encToken = this.getCore('eToken');
    const item1 = await Storage.get({ key: key});
    // getting encrypted data
    let newValue= null;
    if(item1.value != null){
      newValue = this.encrypt.stringDecryption(JSON.parse(item1.value), encToken);
    } else {
      newValue = null;
    }
    return JSON.parse(newValue);
    
  }

  //Removing data from Storage
  async remove(key: string): Promise<void> {
    await Storage.remove({
      key: key,
    });
  }

  //Clearing Storage
  async clear() {
    await Storage.clear();
  };
  async setCore(key: string, value: any): Promise<void> {
    await Storage.set({
      key: key,
      value: JSON.stringify(value),
    });
  }
  async getCore(key: string): Promise<any> {
    const item = await Storage.get({ key: key });
    return JSON.parse(item.value);
  }
}