import { ChangePrimaryEmailPage } from './pages/change-primary-email/change-primary-email.page';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import {OverlayContainer} from '@angular/cdk/overlay';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule }  from '@angular/material/snack-bar';
import { MobilePage } from './pages/mobile/mobile.page';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { IdleExpiry, SimpleExpiry } from '@ng-idle/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
export class AppOverlayContainer extends OverlayContainer {

  protected _createContainer(): void {
    const container: HTMLDivElement = document.createElement('div');
    container.classList.add('app-overlay-container');
    container.classList.add('cdk-overlay-container');
    const element: Element | null = document.querySelector('#angular-app-root');
    if (element !== null) {
      element.appendChild(container);
      this._containerElement = container;
    }
  }
}

@NgModule({
  declarations: [AppComponent, MobilePage, ChangePrimaryEmailPage],
  entryComponents: [MobilePage,ChangePrimaryEmailPage],
  imports: [ MatDatepickerModule,
    MatNativeDateModule, BrowserModule, HttpClientModule, IonicModule.forRoot(), AppRoutingModule,MatSelectModule, MatDialogModule, BrowserAnimationsModule,
    MatIconModule,MatProgressSpinnerModule, FormsModule, ReactiveFormsModule,MatSnackBarModule,ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),NgIdleKeepaliveModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}),],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    [FileOpener],
    Geolocation,
    HTTP,
    AppVersion,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: OverlayContainer, useClass: AppOverlayContainer},
    {
      provide: IdleExpiry,
      useClass: SimpleExpiry
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
