import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private isUserLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private alertService: AlertService,
    private storageService: StorageService,
    private encrypt: EncryptService,
    private http: HttpClient,
    private router: Router,
    private platform: Platform
  ) {
  }

  setIsUserLoggedIn(isLoggedIn: boolean) {
    this.isUserLoggedIn.next(isLoggedIn);
  }

  getIsUserLoggedIn(): Observable<boolean> {
    return this.isUserLoggedIn.asObservable();
  }

  loginService(user: any) {
    const username = this.transform(user.userName); 
    const encryptedUser = this.encrypt.generateEncryption(username);
    const encryptedPassword = this.encrypt.generateEncryption(user.password);
    return this.http.post(environment.apiEnrolUrl + '/auth/login-password-v2', {
      'userName': encryptedUser,
      'password': encryptedPassword,
      'portal': 'web'
    });
  }

  otpLogin(user: any) {
    const encryptedUser = this.encrypt.generateEncryption(user.userName);
    const encryptedPassword = this.encrypt.generateEncryption(user.otpValue);
    return this.http.post(environment.apiEnrolUrl + '/auth/login-otp-v2', {
      'userName': encryptedUser,
      'password': encryptedPassword,
      'portal':'web'
    });
  }

  triggerOtpV2(userName: string, client: string) {
    const encryptedUser = this.encrypt.generateEncryption(userName);
    return this.http.post(environment.apiEnrolUrl + '/auth/trigger-login-otp-v2',
      {
        'userName': encryptedUser,
        'client': client
      });
  }

  forgotPassword(userName: any, client: string) {
    const encryptedUser = this.encrypt.generateEncryption(userName);
    return this.http.post(environment.apiEnrolUrl + '/auth/forgot-password-kmd',
      {
        'userName': encryptedUser,
        'client': client
      });
  }

  VerifyOTP(userName: any, otp: any, tempToken: string) {
    const encryptedUser = this.encrypt.generateEncryption(userName);
    return this.http.post(environment.apiEnrolUrl + '/auth/reset-password-verify-otp-kmd', {
      'userName': encryptedUser,
      'otp': otp,
      'tempToken': tempToken
    });
  }

  changePassword(newPassword: any, confirmNewPassword: any) {
    const password = this.encrypt.generateEncryption(newPassword);
    const confirmPassword = this.encrypt.generateEncryption(confirmNewPassword);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/change-password',
      {
        'newPassword': password,
        'confirmNewPassword': confirmPassword,
      }, httpOptions);
  }

  logout() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + "/truecover/member/logout", httpOptions).subscribe(
      data => {
        this.storageService.clear().then(data=>{
          this.platform.ready().then(() => {
            if (this.platform.width() > 912) {
            } else {
              this.alertService.presentAlert('Success', '', 'Logged Out Successfully', 'success');
            }
          });
          this.router.navigateByUrl("/public/login").then(()=>{
            window.location.reload();
          });
        });
        
      }, error => {
        if (error.error.message === "Session logged out. Please login again!" || error.message === "Session logged out. Please login again!") {
          if (this.platform.width() > 912) {
          } 
          this.storageService.clear().then(data=>{
            this.router.navigateByUrl("/public/login").then(()=>{
              window.location.reload();
            });
          });
          
        }
      }
    );
  }
  logoutV2() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + "/truecover/member/logout", httpOptions).subscribe(
      data => {
        this.storageService.clear().then(data=>{          
        }); 
      }, error => {
        if (error.error.message === "Session logged out. Please login again!" || error.message === "Session logged out. Please login again!") {
          if (this.platform.width() > 912) {
          } 
          this.storageService.clear().then(data=>{
           
          });
          
        }
      }
    );
  }
  transform(value: string) {
    if(value){
        var result = value.replace(/<\/?[^>]+>/gi, ""); //removing html tag using regex pattern
       return result;
    }
    else{}
  }
  forceLoginService(user: any) {
    const username = this.transform(user.userName); 
    const encryptedUser = this.encrypt.generateEncryption(username);
    const encryptedPassword = this.encrypt.generateEncryption(user.password);
    return this.http.post(environment.apiEnrolUrl + '/auth/force-login', {
      'userName': encryptedUser,
      'password': encryptedPassword,
      'portal': 'web'
    });
  }
}
