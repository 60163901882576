import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/services/storage.service'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HomeService {
  public encrypted_email_param: string;
  private messageSource = new BehaviorSubject('email');
  currentMessage = this.messageSource.asObservable();
  private encrypted_mobile = new BehaviorSubject<any>(0);
  currentEncryptedMobile = this.encrypted_mobile.asObservable();
  private selectedcc = new BehaviorSubject<any>(0);
  currentSelectedcc = this.selectedcc.asObservable();
  private email = new BehaviorSubject<any>(0);
  currentselectedEmail = this.email.asObservable();
  private OTP = new BehaviorSubject<any>(0);
  currentselectedOTP = this.OTP.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) { }
  setEncryptedMobile(encr_mobile: any) {
    this.encrypted_mobile.next(encr_mobile);
    }
    setCurrentcc(cc: any) {
        this.selectedcc.next(cc);
    }
    setEncryptedEmail(email: any) {
        this.email.next(email);
    }
    setOTP(OTP: any) {
        this.OTP.next(OTP);
    }
    changeMessage(message: string) {
        this.messageSource.next(message);
    }

  getAllInsuredMembersForHealth(policyId: any) {
    const param = new HttpParams().set('policyType', policyId);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/corporatepolicy/getAllInsuredMembers',
      httpOptions);
  }

  getCorpPolicyDetails(policyType: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams().set('policyType', policyType)
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/corporatepolicy/getCorpPolicyDetails', httpOptions);
  }

  fetchSubmittedClaims(member_ids) {
    const params = new HttpParams().set('member_ids', member_ids);
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/corporatepolicy/get-submitted-claims', options);
  }

  getClaimIntimation(memberId: any) {
    const param = new HttpParams().set('memberId', memberId);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };

    return this.http.get(environment.apiEnrolUrl + '/truecover/get-claim-intimation-history', httpOptions);
  }

  bannerDetails() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/get-banner-data',
      httpOptions);
  }

  marketDetails(type) {
    const param = new HttpParams().set('type', type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/get-banner-data-by-type',
      httpOptions);
  }

  getPreSignedUrlByKey(bucket, key) {
    const param = new HttpParams().set('bucket', bucket)
      .set('key', key);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/s3/get-pre-signed-url-by-key', httpOptions);
  }

  getApplicableRelationshipTypes() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/relationship-list', httpOptions);
  }

  getCountryCode() {
    const options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/auth/country-codes');
  }

  addMember(memDetails: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/add-family-member', memDetails, httpOptions);
  }

  deleteMember(id: any) {
    const param = new HttpParams().set('familyMemberId', id);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/kmd-consumer/delete-family-member', httpOptions);
  }

  getProfile() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/kmd-consumer/get-profile', httpOptions);
  }

  insurerNameAndId() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/insurer-name-and-id',
      httpOptions);
  }

  checkUser() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/contact-us/checkuser', httpOptions);
  }

  saveSecuredContactUs(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/contact-us/employee-contact-us', data, httpOptions);
  }

  getSupportDetails() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/contact-us/getSupportDetails', httpOptions);
  }

  GetArticle() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/community/get-articles',
      httpOptions);
  }

  async getStates() {
    const userId = await this.storageService.get('userId');
    const param = new HttpParams().set('userId', userId);
    const httpOptions = {
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/city/getStates', httpOptions);
  }

  getCities(stateId: any) {
    const param = new HttpParams().set('id', stateId);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/statecity/getCityByState', httpOptions);
  }

  saveMidTermMember(midTermMemberObj: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/enroll/add-midterm-member', midTermMemberObj, httpOptions);
  }

  checkForMemberEnrollment() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/enroll/check-for-member-addition-condition', httpOptions);
  }

  refreshToken(refreshToken){
   
    const param = new HttpParams().set('token', refreshToken);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get<any>(environment.apiEnrolUrl + "/auth/renewToken", httpOptions);
  }
  getGCities() {
    return this.http.get(environment.apiEnrolUrl + '/statesAndCity/getAllCities');
  }
  getTreatments() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/glamyo/get-all-treatment-name', httpOptions);
  }
  saveGlamyoLead(submitData: any, userId:any) {
    const param = new HttpParams().set('userId', userId);
    const options = {
      params: param
    };
    return this.http.post(environment.apiEnrolUrl+ '/truecover/glamyo/save-truecover-lead', submitData, options);
  }
  MemberUniqueIdentifierExistsForAffilate(affialteId: any): Observable<any> {
    const param = new HttpParams().set('affilateId', affialteId);
    const options = {
      params: param
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/banner', options);
  }
  BannerDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/get-banner-data',
      httpOptions);
  }

  mfineSilentLogin(affilate,entityId) {
    const param = new HttpParams().set('entityId', entityId).set('affiliateId', affilate);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/mfine/mfine-silent-login', httpOptions);
  }
  oneMgGenerateHashData(entityId,affialteId){
    const param = new HttpParams().set('entityId', entityId).set('affiliateId', affialteId);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/1mg/generatHashData', httpOptions);
  }

  mfineSubscribe(affilate,entityId) {
    const param = new HttpParams().set('entityId', entityId).set('affiliateId', affilate);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/mfine/mfine-subscribe', httpOptions);
  }
  getDetails(entity){
    const param = new HttpParams().set('entityId', entity);
    const options = {
      params: param
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/contact-support', options);
  }

  AddMobile(get_M, selectedcc): Observable<any> {
    // var finalselectedcc= selectedcc.substring(1);
    // Replace + in CountryCode with &43;
    const re = /\+/;
    const str = selectedcc;
    const newstr = str.replace(re, '&#43;');
    const mobile_param = new HttpParams().set('mobile', get_M).set('countryCode', newstr);   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: mobile_param
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/kmd-consumer/member/add-other-mobile/send-otp',
      httpOptions);
  }
  MarkEmailPrimary(email_id: any, userID: string): Observable<any> {
    // const userID = await this.storageService.get('userId');
    email_id = email_id.toString();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/mark-email-primary',
      {
        'userId': userID,
        'markPrimaryId': email_id,
      },
      httpOptions
    );
  }

  MarkMobilePrimary(mobile_id: string, userID: string): Observable<any> {
    // const userID = localStorage.getItem('userId');
    const mobile_id_toSend = mobile_id.toString();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/mark-mobile-primary',
      {
        'userId': userID,
        'markPrimaryId': mobile_id_toSend,
      },
      httpOptions
    );
  }
  DeleteMobile(countryCode: any, mobile: any, mobileId: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const re = /\+/;
    const str = countryCode;
    const newstr = str.replace(re, '&#43;');
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/delete-mobile',
      {
        'userId': mobileId,
        'countryCode': newstr,
        'mobile': mobile
      },
      httpOptions
    );
  }
  DeleteEmail(submitData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(environment.apiEnrolUrl + '/truecover/kmd-consumer/delete-email', submitData,
      httpOptions
    );
  }
  makePrimaryEmail(newEmail: any, oldEmail: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/member/change-primary-email',
      {
        'currentPrimaryEmailId': oldEmail,
        'newPrimaryEmailId': newEmail,
      },
      httpOptions
    );
  }
  ValidateOtpMobile(otp: String, mobile: string, countryCode: string, userID: string): Observable<any> {
    // const userID = localStorage.getItem('userId');
    // const role = localStorage.getItem('role');
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const re = /\+/;
    const str = countryCode;
    const newstr = str.replace(re, '&#43;');
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/member/add-other-mobile/validate-otp',
      {
        'userId': userID,
        'role': 'CORPORATE_CONSUMER',
        'countryCode': newstr,
        'mobile': mobile,
        'otp': otp
      },
      httpOptions
    );
  }
  AddEmail(get_E): Observable<any> {
    const email_param = new HttpParams().set('email', get_E);
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: email_param
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/kmd-consumer/member/add-other-email/send-otp',
      httpOptions);
  }
  ValidateOtpEmail(otp: String, email: string, userID: string): Observable<any> {
    // const userID = localStorage.getItem('userId');
    // const role = localStorage.getItem('role');
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/member/add-other-email/validate-otp',
      {
        'userId': userID,
        'role': 'CORPORATE_CONSUMER',
        'email': email,
        'otp': otp
      },
      httpOptions
    );
  }
  ValidateOtpPrimaryEmail(otp: String, email: string, userID: string): Observable<any> {
    // const userID = localStorage.getItem('userId');
    // const role = localStorage.getItem('role');
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/member/validate-otp-change-email',
      {
        'userId': userID,
        'role': 'CORPORATE_CONSUMER',
        'email': email,
        'otp': otp
      },
      httpOptions
    );
  }
  getDynamicText(endpoint?:string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(environment.apiEnrolUrl + (endpoint ? endpoint : '/truecover/enroll/get-dynamic-text'),
      httpOptions);
  }
  AddMember(name: any, mobile: any, cc: any, email: any, relation: any, dob: Date, userID): Observable<any> {
    const date = new Date(dob);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const re = /\+/;
    const str = cc;
    const newstr = str.replace(re, '&#43;');
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/add-family-member',
      {
        'userId': userID,
        'countryCode': newstr,
        'familyMemberMobile': mobile,
        'familyMemberName': name,
        'familyMemberEmail': email,
        'relation': relation,
        'familyMemberDob': date
      },
      httpOptions
    );
  }
  EditMember(familyMemberID: any, name: any, mobile: any, cc: any, email: any, relation: any, dob: Date, userID:any): Observable<any> {
    const date = new Date(dob);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const re = /\+/;
    const str = cc;
    const newstr = str.replace(re, '&#43;');
    return this.http.post(environment.apiEnrolUrl + '/truecover/kmd-consumer/edit-family-member',
      {
        'familyMemberId': familyMemberID,
        'userId': userID,
        'countryCode': newstr,
        'familyMemberMobile': mobile,
        'familyMemberName': name,
        'familyMemberEmail': email,
        'relation': relation,
        'familyMemberDob': date
      },
      httpOptions
    );
  }
  CorporatePolicyDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<any>(environment.apiEnrolUrl + '/truecover/get-corporate-policies',
      httpOptions);
  }
  entityWiseAffilitateData(affiliateId, entityId){
    let httpParm = new HttpParams().set('affiliateId', affiliateId).set('entityId', entityId);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: httpParm
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/affiliate/entity-wise-affilitate-data', httpOptions);
  }
  getCityAndStateByPincode(pincode):Observable<any>{
    let httpParm = new HttpParams().set('pincode', pincode);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: httpParm
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/getCityAndStateByPincode', httpOptions);
  }

  getChecklists(){
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Token ' + token
      }),
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/checklist/get-checklist', httpOptions);
  }
  getBsiDetails(){
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  return this.http.get(environment.apiEnrolUrl +'/truecover/corporatepolicy/getBsiDetails', httpOptions);
}
getMarketDetails(entityId,affiliateId) {
  const param = new HttpParams().set('entityId', entityId).set('affiliateId',affiliateId);
  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    params: param
  };
  return this.http.get(environment.apiEnrolUrl + '/truecover/marketPlace',
    httpOptions);
}
  post = (endpoint:string,paramsArg?:object,data?:any):Observable<any> => {

    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      }),
      params: params
    };

    return this.http.post<Observable<any>>(environment.apiEnrolUrl + endpoint, data ? data : '',httpOptions);
  }
  
  getGroupNotesByPolicyId(policyId: any, coverId: any): Observable<any> {
    const url = `${environment.apiEnrolUrl}/truecover/corporatepolicy/getGroupNotesByPolicyId`;
    const params = new HttpParams()
      .set('policyId', coverId)
      .set('coverId', policyId);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }
}