export const apiConst = {
  encdef: 'vote for NDM',
  bucdef: 'uat-akna-corporate-policy-docs',
  bucpol: 'uat-akna-member-uploaded-policies',
  conpho: 'uat-akna-consumer-photographs',
  bandef: 'uat-akna-banner-bucket',
  clidef: 'akna',
  cladoc: 'uat-akna-claim-docs',
  idtdoc: 'uat-akna-tempidcard',
};
